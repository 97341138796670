CSSTRANSFORM  = Modernizr.prefixed("transform")
TRANSITION_PREFIXED     = Modernizr.prefixed('transition')
TRANSITION_END_EVENTS   = {
  'WebkitTransition': 'webkitTransitionEnd',
  'MozTransition'   : 'transitionend',
  'OTransition'     : 'oTransitionEnd',
  'msTransition'    : 'MSTransitionEnd',
  'transition'      : 'transitionend'
}

class Slideshow 
  constructor: ($el, timer) ->
    @$slideshow = $el
    @$slider    = @$slideshow.find('.js_slider')
    @$slides    = @$slider.find('.js_slide')
    @slides     = $.makeArray(@$slides)

    # Width of the slider viewport
    # @type {Number}
    @width = @$slider.width()

    # Flag if user is scrolling
    # @type {Boolean}
    @isScrolling = false
    
    # State: currently animating
    # @type {Boolean}
    @isAnimating = false
    
    # touchstart
    # type {Array}
    @startPos = []
    
    # State: currently dragging
    # @type {Number}
    @deltaX = null

    # Initializing element indexes for slides[]
    @nextElIndex = null
    @prevElIndex = null
    
    # State: Timer duration
    # @type {Number}
    @timerDuration = timer || null
    
    # State: Timer
    # @type {Function}
    @timer = @setAutoplay(@timerDuration)
    
    @transforms = []
    @loop()
    
    @$slideshow.on(TRANSITION_END_EVENTS[TRANSITION_PREFIXED], (e) =>
      @isAnimating = false
      
      # After revolve() was called, prevElIndex represents ex [0] or currentSlide 
      if e.target is @slides[@prevElIndex]
        e.target.style.transition = 'none'
        e.target.style[CSSTRANSFORM] = ''
        setTimeout(->
          e.target.style.transition = ''
        ,20)
      
      if not @timer
        @setAutoplay()
      return
    )

    @$slideshow.on('click', '.js_next, .js_prev', (e) =>
      if @isAnimating
        return
     
      @clearAutoplay(@timer)
      offset = if $(e.target).hasClass('js_prev') then -1 else 1
      @navigate(offset)
      return
    )
  
    $(window).on('resize', (e) =>
      @resize()
    )
  
    @$slider.on('touchstart', (e) =>
      @deltaX = null
      touch = e.originalEvent.targetTouches[0]
      @startPos = [touch.clientX, touch.clientY]
      return
    )

    @$slider.on('touchmove', (e) =>
      touch = e.originalEvent.targetTouches[0]
      deltaPos = [touch.clientX - @startPos[0], touch.clientY - @startPos[1]]
      @deltaX = deltaPos[0]
      
      activeSlides = @updateActiveSlides()
      
      if Math.abs(@deltaX) < Math.abs(deltaPos[1])
        @isScrolling = true
        return
      
      if Math.abs(@deltaX) > @width * 1.25
       return
      
      if not @isDragging
        @isDragging = true
        @isScrolling = false
        
        @clearAutoplay(@timer)
        
        activeSlides.prev.style.transition = 'none'
        activeSlides.prev.style[CSSTRANSFORM] = 'translate3d(-100%,0,0)'
        
      if activeSlides
        @transforms.push({
          el: activeSlides.prev,
          prop: CSSTRANSFORM,
          style: 'translate3d(' + (- @width + @deltaX) + 'px,0,0)'
        })

        @transforms.push({
          el: activeSlides.current,
          prop: 'transition',
          style: 'none'
        })
    
        @transforms.push({
          el: activeSlides.current,
          prop: CSSTRANSFORM,
          style: 'translate3d(' + @deltaX + 'px,0,0)'
        })
    
    
        @transforms.push({
          el: activeSlides.next,
          prop: 'transition',
          style: 'none'
        })
 
        @transforms.push({
          el: activeSlides.next,
          prop: CSSTRANSFORM,
          style: 'translate3d(' + (@width - @deltaX/-1) + 'px,0,0)'
        })
           
      return
    )
  
    @$slider.on('touchend', (e) =>
      @isDragging = false
      
      if @isScrolling
        return
      
      touch = e.originalEvent.changedTouches[0]
      offset = if @startPos[0] < touch.clientX then -1 else 1
      
      @snapToGrid(offset)
      return
    )
  
  loop: =>
    window.requestAnimationFrame(() =>
      if @isDragging
        $.each(@transforms, (index, transform) ->
          transform.el.style[transform.prop] = transform.style
        )
        @transforms = []
      @loop()
    )
  
  updateActiveSlides: (offset) =>
    offset = offset || 1
    # Updating global element indexes
    @nextElIndex = (@slides.length + offset) % @slides.length
    @prevElIndex = (@slides.length + (offset/-1)) % @slides.length

    # Translating indexes to elements
    return activeSlides = {
      prev : @slides[@prevElIndex],
      current : @slides[0],
      next : @slides[@nextElIndex]
    }
    
  snapToGrid: (offset) =>
    @isAnimating = true
    activeSlides = @updateActiveSlides(offset)
    
    # Slide out currentSLide, slide in nextSlide and reset prevSlide
    activeSlides.prev.style.transition = 'none'
    activeSlides.prev.style[CSSTRANSFORM] = 'translate3d(100%,0,0)'
    activeSlides.current.classList.remove('active');
    activeSlides.current.style.transition = ''
    activeSlides.current.style[CSSTRANSFORM] = 'translate3d(' + 100 * (offset/-1) + '%,0,0)'

    activeSlides.next.style.transition = ''
    activeSlides.next.style[CSSTRANSFORM] = ''
    activeSlides.next.classList.add('active')
    @slides = @revolve(@slides, offset)
    return
    
  # Revolver to revolve array entries
  revolve: (elements, offset) =>
    if offset < 0
      # Reduce index of all elements by 1 and move last element to index 0
      elements.unshift(elements.pop())
    else
      # Increase index of all elements by 1 and move first element to last index
      elements.push(elements.shift())

    return elements

  navigate: (offset) =>
    @isAnimating = true
    activeSlides = @updateActiveSlides(offset)

    # Move next slide to initial position
    activeSlides.next.style.transition = 'none'
    activeSlides.next.style[CSSTRANSFORM] = 'translate3d(' + 100 * offset + '%,0,0)'
    
    # After event loop finished (set transition to none was applied), slide out currentSLide and slide in nextSlide
    setTimeout(=>
      @snapToGrid(offset)
    ,20)

    return

  setAutoplay: (timing) =>
    if not @timerDuration
      return 
      
    return @timer = window.setInterval(=>
      @navigate(1)
      return
    , @timerDuration)
  
  clearAutoplay: (timer) =>
    if timer
      window.clearInterval(timer)
      @timer = undefined
    return
  
  resize: =>
    @width = @$slider.width()

slideshow = new Slideshow($('.js_slideshow'), 5000)